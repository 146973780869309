import {
  Banner,
  Box,
  Container,
  Heading,
  Helmet,
  IconCommunity,
  IconCounselling,
  IconEnglish,
  IconFindingWork,
  IconHealthServices,
  IconHousing,
  IconKangaroo,
  IconLegalSupport,
  IconLink,
  IconManagingMoney,
  IconSchooling,
  IconSupportCoordination,
  Interfaces,
  Layout,
  Link,
  PdfLink,
  PreStyledComponents,
  Row,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"
import { ThemeProps, withTheme } from "styled-components"
import { graphql, withPrefix } from "gatsby"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { RefugeesAndAsylumSeekersForm } from "@life-without-barriers/shared-contact-form"

const { corporateThemeFull } = Theme
const { IconWrapper } = PreStyledComponents

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

interface OurServicesProps {
  services: Array<{
    title: string
    icon: string
    iconHeight?: string
  }>
}

interface AddressProps {
  streetAddress: string
  locality: string
  region: string
  postalCode: string
  displayPhoneNumber: string
  callPhoneNumber: string
  telLinkFormat: string
}

const Address = ({
  streetAddress,
  locality,
  region,
  postalCode,
  displayPhoneNumber,
  callPhoneNumber,
  telLinkFormat
}: AddressProps) => (
  <div
    className="mt3 pr3-ns"
    itemScope
    itemType="http://schema.org/ContactPoint"
  >
    <div itemScope itemType="schema.org/PostalAddress">
      <div className="fw7" itemProp="addressLocality">
        {locality}
      </div>
      <div itemProp="streetAddress">{streetAddress}</div>
      <div>
        <span itemProp="addressLocality">{locality}</span>{" "}
        <span itemProp="addressRegion">{region}</span>{" "}
        <span itemProp="postalCode">{postalCode}</span>
      </div>
    </div>
    <div className="db" itemProp="telephone">
      Phone:
      <Link
        className="black fw4 link"
        to={"tel:" + callPhoneNumber}
        ariaLabel={"Phone number " + telLinkFormat}
      >
        {" " + displayPhoneNumber}
      </Link>
    </div>
  </div>
)

const iconSelector = (type: string, color: string, height?: string) => {
  if (type === "managingmoney") {
    return <IconManagingMoney color={color} height={height} />
  }
  if (type === "healthservices") {
    return <IconHealthServices color={color} height={height} />
  }
  if (type === "community") {
    return <IconCommunity color={color} height={height} />
  }
  if (type === "findingwork") {
    return <IconFindingWork color={color} height={height} />
  }
  if (type === "legalsupport") {
    return <IconLegalSupport color={color} height={height} />
  }
  if (type === "link") {
    return <IconLink color={color} height={height} />
  }
  if (type === "english") {
    return <IconEnglish color={color} height={height} />
  }
  if (type === "counselling") {
    return <IconCounselling color={color} height={height} />
  }
  if (type === "kangaroo") {
    return <IconKangaroo color={color} height={height} />
  }
  if (type === "housing") {
    return <IconHousing color={color} height={height} />
  }
  if (type === "schooling") {
    return <IconSchooling color={color} height={height} />
  }
  if (type === "supportcoordination") {
    return <IconSupportCoordination color={color} height={height} />
  }
  return undefined
}

const PdfLinkAdjust = withTheme(({ theme }: ThemeProps<Interfaces.Theme>) => (
  <PdfLink
    className="mt4"
    color={theme.dark}
    to={withPrefix("/Support-for-refugees-and-asylum-seekers-LWB-new.pdf")}
    title="Support for refugees and asylum seekers"
  />
))

const OurServices = withTheme(
  ({ services, theme }: OurServicesProps & ThemeProps<Interfaces.Theme>) => (
    <>
      {/* Mobile */}
      <div className="db dn-ns ph3-ns pv0-ns mt4">
        {services.map(({ title, icon }) => (
          <div key={title} className="flex pv3 ph2 flex items-center">
            <div className="w-25">{iconSelector(icon, theme.medium, "40")}</div>
            <div className="w-75">
              <h3 className="ts-display-4 fw7">{title}</h3>
            </div>
          </div>
        ))}
      </div>
      {/* Desktop */}
      <div className="dn flex-ns flex-wrap">
        {services.map(({ title, icon, iconHeight }) => (
          <div
            key={title}
            className="w-100 w-50-m w-third-l pt5 flex items-center"
          >
            <IconWrapper size="75" color={theme.xxxlight}>
              {iconSelector(icon, theme.medium, iconHeight ? iconHeight : "35")}
            </IconWrapper>
            <h3 className="ts-display-5 fw6 ph3 ph4-l">{title}</h3>
          </div>
        ))}
      </div>
    </>
  )
)

const RefugeesAndAsylumSeekersPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Refugees & asylum seekers | ${siteMetadata.title}`}
        description="Life Without Barriers supports refugees and asylum seekers who are living in the Australian community, while their immigration status is resolved."
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={corporateThemeFull.medium}
      />
      <Layout
        theme={corporateThemeFull}
        fixedCTAPhoneNumber="1800935483"
        fixedCTAContact={
          <RefugeesAndAsylumSeekersForm formId="refugees-and-asylum-seekers-cta" />
        }
      >
        <Banner
          title="Refugees & asylum seekers"
          subTitle="Life Without Barriers supports refugees and asylum seekers who are living in the Australian community, while their immigration status is resolved."
          image={bannerImage}
          breadcrumbs={[routesObject.home, routesObject.service]}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable">
              <Heading size={2}>How can we help you?</Heading>
              <p>
                We can help you access the services you need while a decision is
                being made about your visa and immigration status.
              </p>
            </Box>
            <Box className="w-100">
              <OurServices
                services={[
                  {
                    icon: "managingmoney",
                    title: "Managing money"
                  },
                  {
                    icon: "healthservices",
                    title: "Health and medical services"
                  },
                  {
                    icon: "community",
                    title: "Getting to know your local community"
                  },
                  {
                    icon: "findingwork",
                    title: "Finding a job"
                  },
                  {
                    icon: "legalsupport",
                    iconHeight: "30",
                    title: "Legal support"
                  },
                  {
                    icon: "link",
                    iconHeight: "27",
                    title: "Links to other services"
                  },
                  {
                    icon: "english",
                    iconHeight: "27",
                    title: "Learning English"
                  },
                  {
                    icon: "counselling",
                    title: "Counselling"
                  },
                  {
                    icon: "kangaroo",
                    title: "Understanding Australia"
                  },
                  {
                    icon: "housing",
                    iconHeight: "30",
                    title: "Housing"
                  },
                  {
                    icon: "schooling",
                    title: "Schooling for children"
                  },
                  {
                    icon: "supportcoordination",
                    title: "Case coordination"
                  }
                ]}
              />
            </Box>
          </Row>
        </Container>
        <div className="bg-lwb-grey-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>Need help now?</Heading>
                <p>
                  If you are currently seeking asylum and experiencing financial
                  hardship, please contact us. We can let you know if
                  you&apos;re eligible for case coordination and income support
                  via Status Resolution Services (SRSS). And, if you are
                  eligible, we can help you apply.
                </p>
                <p>
                  Please call your local office, email us at{" "}
                  <Link
                    className="black underline"
                    to="mailto:NISS_SRSS_Intake@lwb.org.au"
                    title="Email NISS_SRSS_Intake@lwb.org.au"
                  >
                    NISS_SRSS_Intake@lwb.org.au
                  </Link>{" "}
                  or fill out our online form here to get in touch.
                </p>
                <div className="flex-ns mt4 mt5-ns">
                  <div className="w-100 w-50-ns lh-copy">
                    <div className="fw7 color-lwb-theme-dark">
                      New South Wales
                    </div>
                    <Address
                      locality="Parramatta"
                      streetAddress="Suite 104, 100 George Street,"
                      region="NSW"
                      postalCode="2150"
                      displayPhoneNumber="(02) 8830 7601"
                      callPhoneNumber="0288307601"
                      telLinkFormat="0 2. 8 8 3 0. 7 6 0 1"
                    />
                    <div className="fw7 mt4 mt5-ns color-lwb-theme-dark">
                      Queensland
                    </div>
                    <Address
                      locality="Milton"
                      streetAddress="34 Douglas Street,"
                      region="QLD"
                      postalCode="4064"
                      displayPhoneNumber="(07) 3633 1500"
                      callPhoneNumber="0736331500"
                      telLinkFormat="0 7. 3 6 3 3. 1 5 0 0"
                    />
                    <div className="fw7 mt4 mt5-ns color-lwb-theme-dark">
                      South Australia
                    </div>
                    <Address
                      locality="Unley"
                      streetAddress="Level 1, 100 Greenhill Road,"
                      region="SA"
                      postalCode="5061"
                      displayPhoneNumber="(08) 8193 9400"
                      callPhoneNumber="0881939400"
                      telLinkFormat="0 8. 8 1 9 3. 9 4 0 0"
                    />
                    <div className="fw7 mt4 mt5-ns color-lwb-theme-dark">
                      Northern Territory
                    </div>
                    <Address
                      locality="Winnellie"
                      streetAddress="Level 1, 60 Winnellie Road,"
                      region="NT"
                      postalCode="0821"
                      displayPhoneNumber="(08) 8930 2300"
                      callPhoneNumber="0889302300"
                      telLinkFormat="0 8. 8 9 3 0. 2 3 0 0"
                    />
                  </div>
                  <div className="w-100 w-50-ns">
                    <div className="fw7 mt4 mt0-ns color-lwb-theme-dark">
                      Victoria
                    </div>
                    <Address
                      locality="Dandenong"
                      streetAddress="Suite 4B, 329 Thomas Street,"
                      region="VIC"
                      postalCode="3175"
                      displayPhoneNumber="(03) 8752 8500"
                      callPhoneNumber="0387528500"
                      telLinkFormat="0 3. 8 7 5 2. 8 5 0 0"
                    />
                    <Address
                      locality="Sunshine"
                      streetAddress="137 Harvester Road,"
                      region="VIC"
                      postalCode="3020"
                      displayPhoneNumber="(03) 9313 2400"
                      callPhoneNumber="0393132400"
                      telLinkFormat="0 3. 9 3 1 3. 2 4 0 0"
                    />
                    <div className="fw7 mt4 mt5-ns color-lwb-theme-dark">
                      Western Australia
                    </div>
                    <Address
                      locality="Cannington"
                      streetAddress="Serviced by Mercy Care, 11 Pattie Street,"
                      region="WA"
                      postalCode="6107"
                      displayPhoneNumber="(08) 6298 9800"
                      callPhoneNumber="0862989800"
                      telLinkFormat="0 8. 6 2 9 8. 9 8 0 0"
                    />
                    <div className="fw7 mt4 mt5-ns color-lwb-theme-dark">
                      Tasmania
                    </div>
                    <Address
                      locality="Hobart"
                      streetAddress="174 Collins Street,"
                      region="TAS"
                      postalCode="7000"
                      displayPhoneNumber="(03) 6232 9750"
                      callPhoneNumber="0362329750"
                      telLinkFormat="0 3. 6 2 3 2. 9 7 5 0"
                    />
                  </div>
                </div>
                <div>
                  <Heading size={3}>
                    Prefer a brochure to print or pass on?
                  </Heading>
                  <PdfLinkAdjust />
                </div>
              </Box>
              <Box className="w-100 w-third-l mt4 mt0-l">
                <RefugeesAndAsylumSeekersForm formId="refugees-and-asylum-seekers-main" />
              </Box>
            </Row>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: { regex: "/refugees-and-asylum-seekers-banner.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: CONSTRAINED)
      }
    }
  }
`

export default RefugeesAndAsylumSeekersPage
